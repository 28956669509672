<template>
    <div class="user-branch-index">
        <md-tabs @change="tabChange" v-if="user">
            <md-tab :md-active="tabIndex==0" :md-disabled="!hasRole('vendor')" md-label="Branches">
                <user-branches v-if="hasRole('vendor') && tabIndex==0"></user-branches>
            </md-tab>
            <md-tab :md-active="tabIndex==1" :md-disabled="!hasRole('vendor') || !!user.master_vendor_id || tabIndex!=1" md-label="Add Branch">
                <user-edit mode="branch" v-if="hasRole('vendor') && tabIndex==1"></user-edit>
            </md-tab>
        </md-tabs>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import List from "@/components/List";
import UserBranches from "@/components/User/UserBranches";
import UserEdit from "@/components/User/UserEdit";

export default {
    props: ["id"],

    components: {
        UserBranches,
        UserEdit
    },

    data() {
        return {
            tabIndex: 0,
            ready: false
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole"
        })
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (this.id == "branches") {
                    this.tabIndex = 0;
                } else if (this.id == "new-branch") {
                    this.tabIndex = 1;
                }
                setTimeout(() => {
                    this.ready = true;
                }, 0);
            }
        }
    },
    methods: {
        tabChange(index) {
            if (!this.ready) {
                return;
            }

            if (index == 0) {
                this.$router.push(`/admin/users/branches`);
            }

            if (index == 1) {
                this.$router.push(`/admin/users/new-branch`);
            }
        }
    }
};
</script>